import * as CryptoJS from 'crypto-js';
import axios, { Method } from 'axios';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ICustomSearchRecord, ICustomSearchResult, IElasticResult, ISearchRecord } from '../../types/search';
import React, { useEffect, useRef, useState } from 'react';

import { ChevronLeft } from 'react-feather';
import { chatBotChat, customSearch, search, saveChats, savePrompts, saveProfile } from '../../services/firebase';
import { iv, key as secKey } from '../../services/firebase';
import { useDispatch, useSelector } from 'react-redux';
import AdvancedSearchFilter from '../../components/search/AdvancedSearchFilter';
import EnviroSearchFilter from '../../components/search/EnviroSearchFilter';
import { CUSTOM_SEARCH_TYPE } from '../../types/search-type';
// import EnviroAILogo from '../../assets/logo.svg';
import EnviroAILogo from '../../assets/enviroai_new.svg';
import EnviroAiTriangle from '../../assets/enviro.svg';
import GoogleCustomSearch from '../../components/search/GoogleCustomSearch';
import HomeTour from './HomeTour';
import { IAuthContext } from '../../context/auth/auth-context';
import SearchForm from '../../components/search/SearchForm';
import SearchResultContainer from '../../components/search/SearchResultContainer';
import SearchTypePills from '../../components/search/SearchTypePills';
import { TIERS } from '../../types/tiers';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import withAuthContext from '../../context/auth/AuthConsumer';
import mediaQuery from '../../util/media-query';
import { ISearch } from '../../store/aiearth/types';
import { removeGlobalFlag, updateGlobalFlags, updateSearch } from '../../store/aiearth/action';
import SidebarOverlay from '../../components/SidebarOverlay';
import CustomSearchMapOption from '../../components/search/customType/CustomSearchMapOption';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import { CeresStripePayments } from '../../services/stripe';
import { CircularProgress, makeStyles, Container, IconButton } from '@material-ui/core';
import { Widget, addResponseMessage, addUserMessage, dropMessages, toggleWidget, isWidgetOpened, toggleInputDisabled, toggleMsgLoader, setQuickButtons, renderCustomComponent } from 'react-chat-widget';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

import 'react-chat-widget/lib/styles.css';
import SavedChats from './SavedChats';
import Profile from './Profile';
import SavedPrompts from './SavedPrompts';
import EmptyStarIcon from '@material-ui/icons/StarBorder';
import FillStarIcon from '@material-ui/icons/Star';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PersonIcon from '@material-ui/icons/Person';
import ChatIcon from '@material-ui/icons/Chat';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SavedLibrary from './SavedLibrary';
import HeaderEnviroAILogo from '../../assets/header-enviroai-logo.svg';
import { AttachFile, CloseRounded, FileCopy } from '@material-ui/icons';
import ReactDOM from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import showToastMessage from '../../util/showToastMessage';
import { isExxonMobileEmail } from '../Validator';

declare const window: any;

let timerInterval: any = null;
interface ICeresHome {
  tier: TIERS;
}
interface ISearchType {
  match: any;
}
const HomeContainer = styled.div`
  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const ChatBox = styled.div`
.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-top: 3px solid #2F5597;
  border-radius: 50%;
  display:flex;
  animation: spin 1s linear infinite;
  margin: 2px 0px 0px 7px;
  @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
}
},

.rcw-launcher {
  &:not(.rcw-hide-sm) {
    background-color: unset !important;
    box-shadow: unset !important;
    // position:relative;

    > img {
      width: calc(100% + 18px);

    }
    // :hover::before{
    //   opacity:1;
    // }
    // ::before{
    //   position: absolute;
    //   content: "Ask EnviroAI";
    //   font-size: 14px;
    //   color: #FFF;
    //   background-color: #000;
    //   padding: 5px 20px;
    //   height: auto;
    //   width: max-content;
    //   border-radius: 5px;
    //   bottom:62px;
    //   left: 50%;
    //   opacity:0;
    //   z-index:9999;
    //   transform: translate(-50%, 0%);
    // }
  }
}

.rcw-message-text.label-container >p:first-child {
  background-color: #045fbc;
  color: white;
  border-radius: 20px;
}
.rcw-response > .rcw-message-text >p:first-child {
  background-color: #e6e8ec;
  color: black;
  border-radius: 20px;
  width: 357px;
  text-align: center;
},

.rcw-response {
  * {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.5rem;
  }
}

.rcw-widget-container{
  width: 450px;
  z-index: 999;
  &:not(.rcw-full-screen) {
    margin: 0 20px 20px 0 !important;
  }
  &.rcw-close-widget-container {
    margin: 0 46px 20px 0 !important;
  }
},
.rcw-conversation-container {
  justify-content: flex-end;
  box-shadow: none;

  .active-profile {
    max-width: 80%;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .preview-file {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 7px;
    gap: 7px;
    margin-right: 4px;
    flex-direction: row-reverse;

    p {
      color: black;
      margin-bottom: 0 !important;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 12px;
    }
  }
  .preview-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    min-width: 64px;
    overflow: hidden;
    position: relative;
    border: 1px solid #d3d3d3;
    border-radius: 10px;

    > img {
      width: 64px;
      display: block;
      position: absolute;
    }
  }

  .preview-img, .preview-file {
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: hsla(0, 0%, 100%, .8);
      border-radius: 50% !important;
      visibility: hidden;
      padding: 0;
      display: flex;
      justify-content: center;
      height: 20px;
    }

    &:hover .close-button {
      visibility: visible;
      opacity: 1;
      color: grey;
    }

    .rounded-close-button {
      svg {
        font-size: 1rem;
        vertical-align: text-top;
      }
    }
  }

  .rcw-sender {
    flex-direction: column;
    min-height: 60px;
    align-items: flex-start;
    margin: 0 10px 5px 10px;
    border: 1px solid #ddc5b3;
    border-radius: 6px;
    padding: 8px;
    background-color: unset;
    > .rcw-picker-btn {
      display: none;
      background: unset;
      img {
        display: none;
      }
    }
    .chat-input {
      display: flex;
      width: 100%;
      align-items: center;
      > .rcw-send {
        padding: 8px;
        border-radius: 5px;
        font-weight: bold !important;
        font-size: .875rem;
        line-height: 1.5;
        color: #2c190e;
        font-weight: 600;
        background-color: #ddc5b3;
        border-color: #ddc5b3;
        min-width: 75px;

        img {
          display: none;
        }
        &:before {
          content: "Send";
        }
      }
    }
  }
}
.rcw-full-screen {
  z-index: 995;
  width: 100%;

  .rcw-conversation-container.active {
    height: 100% !important;
  }

  .rcw-title {
    // padding-top: 0 !important;
  }

  .rcw-header {
    background-color: #2c190e !important;
  }

  .max-btn, .close-Chat {
    color: #cbdff8;
  }

  @media only screen and (max-height: 770px) {
    .rcw-conversation-container.active {
      height: unset !important;
    }
  }
  @media only screen and (min-width: 768px) {
    width: 80%;
    animation: chatAnimation 1s 1;
    animation-direction: normal;
    @keyframes chatAnimation {
      0%   {right: -80%;}
      100% {right: 0;}
    }
  }
}

.rcw-conversation-container.active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw!important;
  background-color: #FFF;
  height: 664px;

  @media only screen and (max-height: 770px) {
    max-height: 770px;
    height: calc(100vh - 96px);
    min-height: calc(100vh - 96px);
  }

}
.rcw-conversation-container .rcw-header {
  background-color: #5dc5c4;
  padding: 7px 0 7px !important;
}
.rcw-header > .rcw-title {
  font-family: 'Sans Pro';
  padding: 10px;
},
.rcw-client{
  width: 80%;
  align-items: flex-end;
}
.rcw-client .rcw-timestamp {
  color: black;
}

.rcw-client > .rcw-message-text {
  background-color: #045fbc;
  font-size: 16px;
  color: #ffffff;
  max-width:100%;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
},
.rcw-response > .rcw-message-text {
    max-width: 380px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
},
.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text {
  background-color: #8ebbf7!important;
  width: 100%!important;
  color: white !important;
  border: none !important;
}
.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
    --bgColor: #8ebbf7 !important;
  }
}
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  background-color: #c5c5c5;
  width: 100%;
}
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
  --bgColor: #c5c5c5;
  }
}
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  background-color: #FFF;
  color: black;
  border: 1px solid black;
  width: 100%;
}
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
  --bgColor: #FFF;
  }
}
.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
    --lines: 1000;
    --lineHeight: 1.5rem;
    --timePerLine: .2s;
    --widthCh: 40;
    --width: 100%;
    /* do not touch the time property!!! */
    --time: calc(var(--lines) * var(--timePerLine));
    animation: grow var(--time) steps(var(--lines));
    animation-fill-mode: forwards;
    line-height: var(--lineHeight);
    max-height: var(--lineHeight);
    overflow: hidden;
    position: relative;
    width: var(--width);

    @keyframes grow {
      0% { max-height: var(--lineHeight); }
      100% { max-height: calc(var(--lineHeight) * var(--lines)); }
    }

    @keyframes carriageReturn {
      0% { top: 0; }
      100% { top: calc(var(--lineHeight) * var(--lines)); }
    }

    @keyframes type {
      0% { width: 100%; }
      100% { width: 0%; }
    }

    @keyframes caret {
      0% { color: var(--bgColor); }
      100% { color: black; }
    }
  }
}

.rcw-message-text ul {
  margin-bottom: 0 !important;
}

.rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text,
.rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
  > p:nth-child(3), >ul , >ol {
    &:before {
      content: "";
      animation:
        type var(--timePerLine) linear none,
        carriageReturn var(--time) steps(var(--lines)) var(--lines),
        caret 1s steps(2) infinite;
      background: var(--bgColor);
      border-left: 2px solid;
      bottom: 0;
      height: 2rem;
      position: absolute;
      right: 0;
      width: 100%;
      border-left-width: 0;
      animation:
      type var(--timePerLine) steps(var(--widthCh)) infinite,
      carriageReturn var(--time) steps(var(--lines)) var(--lines),
      caret 1s steps(2) infinite;
    }
  }
}
.rcw-launcher {
  background-color: #8ebbf7;
},
.rcw-messages-container{
  // max-height:none;
  height: calc(100vh - 60%);
  margin-bottom: auto;
},
.rcw-full-screen .rcw-messages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: auto;
  height: auto !important;
  overflow: auto;
}

.rcw-message-text li > p {
  margin: -24px 0 0;
}

.rcw-full-screen .rcw-messages-container .rcw-message-client {
  width: 98%;
}

.rcw-full-screen .rcw-messages-container .rcw-message:not(.rcw-message-client) {
  width: 31%;
  // height: 90%;
  align-self: flex-start;
}

.rcw-full-screen .rcw-messages-container > .loader {
  height: fit-content;
}
.loader-container {
  display: flex;
  align-items: center;
  max-width: 550px;
  &:before {
    content: "Accessing the world's most powerful environmental brain. Stand by as it sifts through millions of documents and the internet to produce your response";
    color: black;
    margin-right: 10px;
    font-size:14px;
    font-family:'Roboto', sans-serif;
  }
}

.loader-dots {
    height: 4px;
    width: 8px;
}

.rcw-full-screen > .rcw-messages-container {
  height: 100% !important;
}

.rcw-full-screen .rcw-message:not(.rcw-message-client) .rcw-response {
  width: 100%;
}

.rcw-full-screen .rcw-response > .rcw-message-text {
  min-width: auto !important;
  max-width: none !important;
}

.rcw-full-screen .rcw-response > .rcw-message-text >p:first-child {
  background-color: #e6e8ec;
  color: black;
  border-radius: 20px;
  width: 100%;
  text-align: center;
}

.rcw-new-message{
  color: #000000;
  text-align: left;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  width: calc(100% - 98px);
  .rcw-input {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow: hidden !important;
    max-height: unset;
    .quick-buttons {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
},
.rcw-close-button {
  border: none;
  background: none !important;
  cursor: pointer;
  right: 5px !important;
}
.quick-button {
  border-radius: 49px;
  font-family: 'Sans Pro';
  color:#FFFFFF;
  background-color:#005fbc;
  border:0px;
  display:flex;
  padding: 10px 40px;
  margin-bottom: 5px;

  &:disabled {
    background-color: gray;
    cursor: no-drop;
  }
  &.btn-disabled {
    background-color: gray;
  }
}
.quick-buttons-container{
  padding: 10px;
  position: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 0px;
  overflow-x: unset;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */

  .quick-buttons {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

// .rcw-response > .rcw-message-text {
//   @media only screen and (min-width: 760px) {
//     max-width: 75% !important;
//   }

//   @media only screen and (min-width: 380px) {
//     min-width: 380px !important;
//   }
// }
`;

const IFrameContainer = styled.div`
  // bottom: 0;
  top: 6px;
  margin: 0 500px 20px 0;
  // height: calc(74vh);
  height: calc(100vh - 100px);
  max-height: 770px;
  position: fixed;
  right: 0;
  z-index: 9999;

  @media screen and (min-height: 765px) {
    // bottom: -17px;
    top: calc(100vh - 755px);
    height: calc(100vh - (100vh - 673px));
    margin: 0 500px 0 0;
  }
  .close-iframe {
    position: absolute;
    font-size: 1.5rem;
    top: 16px;
    right: 0.75rem;
    color: #045fbc;
    border: none;
    background: none;
    cursor: pointer;
  }
  .maximize-btn {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 25px;
    right: 40px;
    color: #045fbc;
    border: none;
    background: none;
  },
  iframe {
    max-width: 450px;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .iframe-title {
    border-radius: 0px 0px 10px 10px;
  },
  & container{
    & .title-container {
      background: #5dc5c4;
      height: 70px;
      border-radius: 10px 10px 0px 0px;
    }
    & .web-title {
      font-family: Lazy Dog;
      color: #ffffff;
      font-size: 34px;
      margin: 0;
      padding: 15px 19px 15px 0px;
      text-align: center;
    }
  }
`;

const SearchContainerItem = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  .title-head {
    flex: auto;
  }
  // .saved-chat-btn {
  //   font-size: 1vw;
  // }

  button {
    display: flex;
    font-size: 1vw;
    grid-gap: 3px;
    gap: 3px;
    border-radius: 6px;
    align-items: center;
    border-color: #ddc5b3;
    font-family: "Sans Pro";
  }

  .environmental {
    width: 25%;
    font-family: 'Lazy Dog';
    font-weight: bold;
    align-self: center;
  }
  .environmental > p {
    font-size: 1.2vw;
    margin-bottom: 0;
  }
  .title-head-fullscreen {
    width: 23vw;
    align-self: center;
    // margin-left: 12%;
    flex: none;

    img {
      max-width: 11vw;
    }
  }
  .icon-container {
    padding-right: 20px;
  }
  & .max-btn {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 27px;
    right: 40px;
    color: #045fbc;
    border: none;
    background: none;
  }
  & .close-Chat {
    position: absolute;
    font-size: 1.5rem;
    top: 22px;
    right: 0.75rem;
    color: #045fbc;
    border: none;
    background: none;
    cursor: pointer;
    font-family:monospace;
  }

`;

const CeresLogoContainerItem = styled<any>(SearchContainerItem)`
  flex-direction: column;
  align-items: center;
  gap: 15px;
  min-height: 240px;
  margin-bottom: 0px;

  > p {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 20px;
  }
  @media only screen and (max-width: 1400px) {
    min-height: 200px;
  }
  ${mediaQuery.phone`
    min-height: 170px;
  `}
`;

// const CeresMessage = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   margin: 27px 0;
//   font-family: 'Open Sans';
//   > p {
//     font-weight: bold;
//     margin-bottom: 0;
//   }

//   .question-box {
//     background-color: #ddc5b3;
//     padding: 10px 20px;
//     color: white;
//     border-radius: 10px;
//     font-weight: 600;
//     box-shadow: 2px 2px 10px 10px #ffffff;
//     max-width: 150px;
//     text-align: center;
//   }
// `;

const SearchFormContainerItem = styled<any>(SearchContainerItem)`
  font-weight: 300;
  padding: 0 7rem;
  justify-content: space-between;

  ${mediaQuery.phone`
    padding: 0 1rem;
    flex-wrap: wrap-reverse;
  `}
  ${mediaQuery.tablet`
    padding: 0 1rem;
    flex-wrap: wrap-reverse;
  `}
`;

const SearchContainer = styled.div<ICeresHome>`
  justify-content: center;
  flex-direction: column;
  display: flex;
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    min-height: 600px;
    justify-content: flex-start;
  }
`;
const SearchContainerMain = styled<any>(SearchContainer) <{ issearch: string }>`
  position: sticky;
  background-color: ${(props) => (props.issearch ? '#fff' : 'transparent')};
  top: 0;
  z-index: 99;
  padding-top: 15px;
`;
const SearchResultMainContainer = styled<any>(SearchContainer)`
  flex: 1;
  z-index: 1;
  background-color: #fff;
`;

const CeresBotHeroImage = styled.img`
  width: 100%;
  margin-top: 45px;
  max-width: 525px;
  @media only screen and (max-width: 1400px) {
    max-width: 35%;
  }
  ${mediaQuery.phone`
    width: auto;
    margin-top: 100px;
    max-width: 90%;
  `}
`;
const EnviroAiTriangleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media only screen and (max-width: 1400px) {
    max-width: 100%;
  }

  .logo-container {
    width: 8%;
    max-width: 90px;

    ${mediaQuery.phone`
        width: 100%;
    `}

    img {
      max-width: 100%;
    }
  }
`;
const LaunchText = styled.div`
  font-weight: 700;
  color: #5dc5c4;
`;

const ShowLogoArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${mediaQuery.phone`
    flex-direction: column-reverse;
    flex-grow: 1;
  `}
  ${mediaQuery.tablet`
      flex-direction: column-reverse;
    flex-grow: 1;
  `}
`;
const LogoArea = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-between;
  ${mediaQuery.phone`
    justify-content: flex-end;
    width: 100%;
  `}
  ${mediaQuery.tablet`
     justify-content: flex-end;
     width: 100%;
  `}
  & img {
    ${mediaQuery.phone`
      width: 85px;
    `}
  }
`;
const Title = styled.img`
  width: 140px;
  margin: 10px 30px 25px;
`;
const BackLink = styled.div`
  font-weight: 700;
  color: #336195;
  padding-left: 25px;
  cursor: pointer;
  margin: -10px 0 0 0;
  ${mediaQuery.phone`
    margin: 30px 0 0;
    padding-left: 0;
  `}
`;
const Extension = styled.div`
  padding-left: 200px;
  padding-bottom: 20px;
  width: max-content;
  @media only screen and (max-width: 1200px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 991px) {
    display: flex;
    justify-content: right;
    width: 100%;
  }
  @media only screen and (max-width: 575px) {
    display: none;
  }
  & button {
    background-color: #547eaf !important;
    border-radius: 30px;
    font-family: 'Open Sans';
    font-size: 17px;
    letter-spacing: -1px;
    color: white !important;
    transition: all 0.1s ease-in-out;
    & :hover {
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.1s ease-in-out;
    }
  }
  & img {
    height: 32px;
    width: 32px;
    margin: 0px 3px 2px 0px;
  }
`;

const DaypassContainer = styled.div`
  position: fixed;
  z-index: 9999999;
  top: 12%;
  left: 30%;
  @media only screen and (max-width: 1200px) {
    left: 22%;
  }
  @media only screen and (max-width: 1000px) {
    left: 18%;
  }
  @media only screen and (max-width: 900px) {
    left: 16%;
  }
  @media only screen and (max-width: 750px) {
    left: 4%;
  }
  ${mediaQuery.desktop`
    top: 18%;
  `}
  ${mediaQuery.tablet`
    top: 22%;
  `}
  ${mediaQuery.phone`
    top: 30%;
  `}
`;

const Back = styled.div`
  & img {
    position: relative;
    width: 100%;
  }
  & div.pro {
    & img {
      position: absolute;
      top: 60%;
      left: 0px;
      margin-left: 25px;
      height: 17%;
      width: 20%;
      cursor: pointer;
    }
  }
  & div {
    & img {
      position: absolute;
      top: 80%;
      margin-left: 25px;
      left: 0px;
      height: 10%;
      width: 20%;
      cursor: pointer;
    }
  }
`;

const useStyles = makeStyles({
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },

});

const applyFilterToQuery = (query: any, filter: any = {}, type: string = '') => {
  const innerQuery = query.query || {};
  let releaseDateFilter = {};

  if (filter.rnNumber) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_number: filter.rnNumber,
    });
  }
  if (filter.cityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      city_name: filter.cityName,
    });
  }
  if (filter.category) {
    if (filter.category === 'other') {
      innerQuery.mustNot = {};
      innerQuery.mustNot.matchQuery = Object.assign(
        {},
        {
          record_series_name: 'Air WS WATER WQ WST WASTE',
        }
      );
    } else {
      innerQuery.terms = Object.assign(innerQuery.terms || {}, {
        category_name: filter.category,
      });
      if (filter.recordSeries) {
        innerQuery.terms = Object.assign(innerQuery.terms || {}, {
          record_series_name: filter.recordSeries,
        });
      }
    }
  }
  if (filter.recordTitles) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      title: filter.recordTitles.label,
    });
  }
  if (filter.entityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_name: filter.entityName,
    });
  }
  if (filter.zipCode) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      zip_code: filter.zipCode,
    });
  }
  if (filter.hideEmissionEvents) {
    innerQuery.inclusions = Object.assign(
      {},
      {
        hideEmissionEvents: true,
      }
    );
  }
  if (filter.fromReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      gte: filter.fromReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date',
    });
  }
  if (filter.toReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      lte: filter.toReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date',
    });
  }
  if (Object.keys(releaseDateFilter).length > 0) {
    innerQuery.dateFilters = (innerQuery.dateFilters || []).concat([releaseDateFilter]);
  }
  return { query: innerQuery };
};

const useQuery = () => {
  const { search: searchVal } = useLocation();

  return React.useMemo(() => new URLSearchParams(searchVal), [searchVal]);
};

const CeresHome: React.FC<
  ISearchType & {
    context: IAuthContext;
  }
> = (props) => {
  const context = props.context;
  const history = useHistory();
  const queryParam = useQuery();
  const isDirectCustomSearch: any = queryParam.get('directsearch');
  const hideSearchBar: any = queryParam.get('hideSearchBar');
  const [frameSearchQueryText, setFrameSearchQueryText] = useState(
    props.match.params.query ? props.match.params.query : queryParam.get('q')
  );
  const [searchQueryText, setSearchQueryText] = useState(
    props.match.params.query ? props.match.params.query : queryParam.get('q')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isChatFullScreen, setIsChatFullScreen] = useState(false);
  const [openSavedChat, setOpenSavedChat] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [isExtensionInstalled, setExtensionInstalled] = useState(false);
  // const [dayPassDialog, setDayPassDialog] = useState(true);
  const [isDayPassLoading, setIsDayPassLoading] = useState(false);
  const [quickSerch, setQuickSerch] = useState<any>('');
  const [isQuickFilter, setQuickFilter] = useState(false);
  const [docSearchEPNResult, setDocSearchEPNResult] = useState<any[]>();
  const [filters, setFilter] = useState<any>();
  let filter = filters;
  const [isTourOpen, setTourOpen] = useState(localStorage.getItem('walkthrough') === 'true' ? true : false);
  localStorage.setItem('walkthrough', 'false');
  const [searchResult, setSearchResult] = useState<IElasticResult<ISearchRecord> | null>(null);
  const [customSearchResult, setCustomSearchResult] = useState<ICustomSearchResult<ICustomSearchRecord> | null>(null);
  const [searchType, setSearchType] = useState<CUSTOM_SEARCH_TYPE>(
    queryParam.get('type')
      ? (queryParam.get('type') as CUSTOM_SEARCH_TYPE)
      : context.isAuthenticated
        ? CUSTOM_SEARCH_TYPE.DEFAULT
        : CUSTOM_SEARCH_TYPE.ALL
  );
  const [sourceType, setSourceType] = useState('');
  const [customSearchType, setCustomSearchType] = useState('google');
  const [popup, setpopup] = useState(false);
  const [toggleMobileScreen, setToggleMobileScreen] = useState(true);
  const [defaultData, setDefaultData] = useState('');
  const [defaultData1, setDefaultData1] = useState('');
  const [defaultData2, setDefaultData2] = useState('');
  const [defaultChat, setDefaultChat] = useState<any>(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showSavedPrompts, setShowSavedPrompts] = useState(false);
  const [showSaveLibrary, setShowSaveLibrary] = useState(false);
  const [favorites, setFavorites] = useState<any[]>([]);
  const [showQuestionsPopup, setShowQuestionsPopup] = useState(false);
  const [showFavoriteSpinner, setShowFavoriteSpinner] = useState(false);
  const [savedChatId, setSavedChatId] = useState(null);
  const [activeProfile, setActiveProfile] = useState<string | null>(null);
  const [disabledSavedChatBtn, setDisabledSavedChatBtn] = useState(false);
  const [totalSearchQuestion, setTotalSearchQuestion] = useState(0);
  // eslint-disable-next-line
  const [totalDraftLoad, setTotalDraftLoad] = useState(1);
  // currentQuestion flag use to handle more draft when more then 1 question
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [files, setFiles]: any[] = useState([]);
  const [filePreviews, setFilePreviews]: any[] = useState([]);
  const [chatFilePreviews, setChatFilePreviews]: any[] = useState([]);
  const imageTypes = ['image/jpg','image/jpeg','image/png'];
  const rcwButton = document.querySelector('.rcw-sender');

  const closepopup = () => {
    setpopup(false);
  };

  const routeChange = () => {
    setCustomSearchType('google');
    setSearchType(CUSTOM_SEARCH_TYPE.INTERNET);
    handleSearchTypeChange(CUSTOM_SEARCH_TYPE.INTERNET, '', 1, sort);
    setpopup(false);
  };

  const chatUi = () => {
    setIsChatFullScreen(f => !f);
  };

  const useClickOutside = (handler: any) => {
    // tslint:disable-next-line:no-shadowed-variable
    const main: any = useRef(null);
    useEffect(() => {
      const maybeHandler = (event: any) => {
        if (main && main.current && !main.current.contains(event.target)) {
          if (event.target.innerText && event.target.innerText.includes('Get Normal Search Results')) {
            return;
          }
          handler();
        }
      };

      document.addEventListener('mousedown', maybeHandler);

      return () => {
        document.removeEventListener('mousedown', maybeHandler);
      };
    });

    return main;
  };

  useEffect(() => {
    const messageBody = document.querySelector('#messages');
    if(messageBody) {
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
    adjustInputHeight();
  }, [isChatFullScreen]);

  React.useEffect(() => {
    const userAgent = navigator.userAgent;
    const isChromeCheck = userAgent.match(/chrome|chromium|crios/i);
    if (isChromeCheck) {
      setIsChrome(true);
      if (window && window.chrome && window.chrome.runtime) {
        window.chrome.runtime.sendMessage(process.env.REACT_APP_CHROME_EXTENSION_ID, { message: 'version' },
          // tslint:disable-next-line:only-arrow-functions
          function(reply: any) {
            // tslint:disable-next-line:no-console
            if (reply && reply.version) {
              setExtensionInstalled(true);
            }
          });
      }
    }
  }, []);

  // const draftBtn = [
  //   { label: 'Draft 1', value: 'draft_1' },
  //   { label: 'Draft 2', value: 'draft_2' },
  // ];

  const disableDraftBtns = (val: boolean) => {
    const btnlEls: any = document.getElementsByClassName('quick-button');
    btnlEls.forEach((btnlEl: any) => {
      if (val) {
        btnlEl.setAttribute('disabled', val);
      } else {
        btnlEl.removeAttribute('disabled');
      }
    });
  };

  function QuestionsListModal(modalProps: any) {
    const isSaveEnable = !!favorites.filter(o => o.isFavorite || o.id).length;
    return (
      <Modal
        {...modalProps}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 99999 }}
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add To Saved Prompts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { favorites.map((favorite, i) => (
            <Card key={i} text="primary" className="mb-2">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <Card.Title className="mb-0">{ favorite.question }</Card.Title>
                <IconButton
                  onClick={(e: any) => { onFavoriteClick(i); }}
                  aria-label="fav-icon"
                  component="span"
                >
                  {
                    favorite.isFavorite ? <FillStarIcon style={{color: '#faaf00'}} /> : <EmptyStarIcon style={{color: '#faaf00'}} />
                  }
                </IconButton>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveFavorites} disabled={!isSaveEnable}>
            Save
            { showFavoriteSpinner && <Spinner animation="border" variant="light" size="sm" className="ml-2" /> }
          </Button>
          <Button onClick={modalProps.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const onFavoriteClick = (index: number) => {
    favorites[index].isFavorite = !favorites[index].isFavorite;
  };

  const saveFavorites = async () => {
    try {
      disableDraftBtns(true);
      setShowFavoriteSpinner(true);

      const promptData = favorites.filter(data => data.id || data.isFavorite);
      promptData.forEach(async (item: any, i: number) => {
        if (item.id && item.isFavorite) {
          return;
        } else {
          const actionType = item.id ? 'remove' : 'create';
          await createRemovePrompt(actionType, item, i);
        }
      });

      toast.success('Data Saved Successfully.', { position: 'top-right' });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error);
    } finally {
      setShowFavoriteSpinner(false);
      disableDraftBtns(false);
      setShowQuestionsPopup(false);
    }
  };

  const createRemovePrompt = async (type: string, prompt: any, i: number) => {
    const response = await savePrompts({
      type,
      id: prompt.id,
      record: { promptData: [prompt.question] }
    });

    if(response && response.data) {
      const updatedFavorites = favorites;
      updatedFavorites[i].id = type === 'create' ? response.data.id : '';
      setFavorites(updatedFavorites);
    }
  };

  const getDraftData = async (url: string, defaultQueryParam: string, requestMethod: Method, searchQuery: string, label: string, formData?: any) => {
    const reqUrl = requestMethod === 'GET' ? `${url}${defaultQueryParam}` : url;
    axios(reqUrl, { data: formData ? formData : undefined, method: requestMethod }).then((res: any) => {
      let responseNum = 0;
      setTotalDraftLoad(prevState => {
        responseNum = prevState;
        return prevState;
      });
      const urlParams = new URLSearchParams(window.location.search);
      const question = urlParams.get('q');

      let totalQuestion = 0;
      setTotalSearchQuestion(prevState => {
        totalQuestion = prevState;
        return prevState;
      });

      // prevent to display previous chat drafts
      if((totalQuestion === 0 && question !== searchQuery) || responseNum > 3) {
        return;
      }
      if (res && res.data) {
        if (process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test') {
          // tslint:disable-next-line:no-console
          console.log('test >> res', res);
          // tslint:disable-next-line:no-console
          console.log('test >> res.data', res.data);
          // tslint:disable-next-line:no-console
          console.log('test >> res.data.content', res.data.content);

          const newMainString = res.data.content;
          const newResponse = `EnviroAI Model Response #${responseNum} - ${label}\n\n${newMainString}`;
          switch (responseNum) {
            case 1:
              setDefaultData(newResponse);
              break;
            case 2:
              setDefaultData1(newResponse);
              break;
            case 3:
              setDefaultData2(newResponse);
              break;
            default:
              break;
          }
          renderCustomComponent(customResponseComponent, { tag: `EnviroAI Model Response #${responseNum} - ${label}`, message: newMainString });
        } else {
          // tslint:disable-next-line:no-console
          console.log('prod >> res',res);
          // tslint:disable-next-line:no-console
          console.log('prod >> res.data',res.data);
          // tslint:disable-next-line:no-console
          console.log('prod >> res.data.content',res.data.content);
          const mainString = res.data.content;
          const response = `EnviroAI Model Response #${responseNum} - ${label}\n\n${mainString}`;
          switch (responseNum) {
            case 1:
              setDefaultData(response);
              break;
            case 2:
              setDefaultData1(response);
              break;
            case 3:
              setDefaultData2(response);
              break;
            default:
              break;
          }
          renderCustomComponent(customResponseComponent, { tag: `EnviroAI Model Response #${responseNum} - ${label}`, message: mainString });
        }
        addClassForLables();
      } else {
        chatBotChat({
          prompt: searchQuery
        }).then(response => {
          if (response.data) {
            renderCustomComponent(customResponseComponent, { tag: `EnviroAI Model Response #${responseNum} - ${label}`, message: response.data });
          }
        });
      }
      if(responseNum === 1 && !isChatFullScreen) {
        setIsChatFullScreen(true);
      }
      if(responseNum === 3) {
        resetStates();
      }
      setTotalDraftLoad(responseNum + 1);
    }).catch((err: any) => {
      let responseNum = 0;
      setTotalDraftLoad(prevState => {
        responseNum = prevState;
        return prevState;
      });
      const urlParams = new URLSearchParams(window.location.search);
      const question = urlParams.get('q');

      let totalQuestion = 0;
      setTotalSearchQuestion(prevState => {
        totalQuestion = prevState;
        return prevState;
      });

      // prevent to display previous chat drafts
      if((totalQuestion === 0 && question !== searchQuery) || responseNum > 3) {
        return;
      }

      if(responseNum === 1 && !isChatFullScreen) {
        setIsChatFullScreen(true);
      }
      if(requestMethod === 'GET') {
        const dataNotFoundMessage = `EnviroAI Model Response #${responseNum} - ${label}\n\nData Not Found`;
        addResponseMessage(dataNotFoundMessage);
        switch (responseNum) {
          case 1:
            setDefaultData(dataNotFoundMessage);
            break;
          case 2:
            setDefaultData1(dataNotFoundMessage);
            break;
          case 3:
            setDefaultData2(dataNotFoundMessage);
            break;
          default:
            break;
        }
        toast.error('Unable to load data');
        addClassForLables();
        if(responseNum === 3) {
          resetStates();
        }
        setTotalDraftLoad(responseNum + 1);
      } else {
        getDraftData(url, defaultQueryParam, 'GET', searchQuery, label);
      }
    });
  };

  const handleQuickButtonClickFn = async (type: any, searchedText?: string) => {
    if(type === 'favorites') {
      if (isExxonMobileEmail(context)) {
        showToastMessage({
          type: 'warn',
          title: 'Access Denied',
          description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
        });
        return;
      }
      if(!favorites.length) {
        setFavorites([{id: '', question: searchQueryText, isFavorite: false}]);
      }
      setShowQuestionsPopup(true);
    }

    if (type === 'other_draft') {
      const searchQuery = searchedText ? searchedText : searchQueryText;
      disableDraftBtns(true);
      setDisabledSavedChatBtn(true);
      const isLoader = document.querySelector('.loader');
      const inputDisabled = document.querySelector('.rcw-new-message');
      setTimeout(() => {
        if(!(isLoader && isLoader.classList.contains('active'))) {
          toggleMsgLoader();
        }
        if(!(inputDisabled && inputDisabled.classList.contains('rcw-message-disable'))) {
          toggleInputDisabled();
        }
      });
      let predQueryString = `?q=${searchQuery}&chatStatus=${totalSearchQuestion > 0 ? 'ongoing' : 'new'}`;

      const formData = new FormData();
      formData.append('q', searchQuery);
      formData.append('chatStatus', totalSearchQuestion > 0 ? 'ongoing' : 'new');

      let activeProfileText = null;
      setActiveProfile(prevState => {
        activeProfileText = prevState;
        return prevState;
      });

      if(activeProfileText) {
        predQueryString += `&profile=${activeProfileText}`;
      }
      const localUserId = localStorage.getItem('uid');
      if(localUserId) {
        predQueryString += `&userId=${localUserId}`;
        formData.append('userId', encodeURIComponent(localUserId));
      }
      if (process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test') {
        if(Array.isArray(files) && files.length) {
          files.forEach((file) => {
            formData.append('files', file);
          });
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI_TEST}`, predQueryString, 'POST', searchQuery, 'Gemini Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT_TEST}`, predQueryString, 'POST', searchQuery, 'OpenAI Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE_TEST}`, predQueryString, 'POST', searchQuery, 'Anthropic Base-Model', formData);
        } else {
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI_TEST}`, predQueryString, 'GET', searchQuery, 'Gemini Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT_TEST}`, predQueryString, 'GET', searchQuery, 'OpenAI Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE_TEST}`, predQueryString, 'GET', searchQuery, 'Anthropic Base-Model');
        }
      } else {
        if(Array.isArray(files) && files.length) {
          files.forEach((file) => {
            formData.append('files', file);
          });
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI}`, predQueryString, 'POST', searchQuery, 'Gemini Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT}`, predQueryString, 'POST', searchQuery, 'OpenAI Base-Model', formData);
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE}`, predQueryString, 'POST', searchQuery, 'Anthropic Base-Model', formData);
        } else {
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI}`, predQueryString, 'GET', searchQuery, 'Gemini Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT}`, predQueryString, 'GET', searchQuery, 'OpenAI Base-Model');
          getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE}`, predQueryString, 'GET', searchQuery, 'Anthropic Base-Model');
        }
      }
    }
    if (type === 'save_chat') {
      const data = {
        q: {
          title: currentQuestion !== '' ? currentQuestion : searchQueryText,
          a1: defaultData,
          a2: defaultData1,
          a3: defaultData2,
          order: 3
        },
        attachFiles: chatFilePreviews
      };

      const storedChats = JSON.parse(localStorage.getItem('defaultChats') as string);
      const defaultStoreData = defaultChat && defaultChat.data ? defaultChat.data.map((question: any) => {
        const questionCloneData = { ...question };
        const questionObj = {
          q: questionCloneData,
          attachFiles: questionCloneData.attachFiles
        };
        delete questionCloneData.attachFiles;
        return questionObj;
      }) : [];

      spinner(true, 0);
      disableDraftBtns(true);

      if(!defaultChat) {
        localStorage.setItem('defaultChats', JSON.stringify({
          mainTitle: searchQueryText,
          data: [data]
        }));
      } else {
        localStorage.setItem('defaultChats', JSON.stringify({
          mainTitle: defaultChat && defaultChat.mainTitle ? defaultChat.mainTitle : searchQueryText,
          data: [...defaultStoreData, data]
        }));
      }

      const response = savedChatId ? await saveChats({
        type: 'update',
        id: savedChatId,
        record: {
          mainTitle: storedChats && storedChats.mainTitle ? storedChats.mainTitle : ((defaultChat && defaultChat.mainTitle) ? defaultChat[0].mainTitle : searchQueryText),
          chatData: [...defaultStoreData, data]
        }
      }) : await saveChats({
        type: 'create',
        record: {
          mainTitle: defaultChat && defaultChat.mainTitle ? defaultChat.mainTitle : searchQueryText,
          chatData: [...defaultStoreData, data]
        }
      });
      disableDraftBtns(false);
      spinner(false, 0);
      if (response && response.data) {
        if(!savedChatId) {
          setSavedChatId(response.data.id);
        }
        toast.success('Data Saved Successfully.', { position: 'top-right' });
      }
    }
  };

  const searchInBot = (text: any, activeProfileData: any = null) => {
    try {
      disableDraftBtns(true);
      setDisabledSavedChatBtn(true);
      // toggleInputDisabled();
      setTotalDraftLoad(1);
      // setSearchQueryText(text);
      handleQuickButtonClickFn('other_draft', text);
    } catch (error) {
      toast.error('Unable to load data');
      // tslint:disable-next-line:no-console
      console.log('chat gpt error', error);
    } finally {
      setFiles([]);
    }
  };

  const spinner = (show: boolean, buttonIndex: number) => {
    const btnEls = document.getElementsByClassName('quick-button');
    const btnEl = btnEls[buttonIndex];
    if (show) {
      const spinnerEl = document.createElement('div');
      spinnerEl.className = 'spinner';
      btnEl.appendChild(spinnerEl);
    } else {
      const spinnerEl = btnEl.querySelector('.spinner');
      if (spinnerEl) {
        spinnerEl.remove();
      }
    }
  };

  const disableQuickButton = (buttonIndex: number) => {
    const btnEls = document.getElementsByClassName('quick-button');
    const btnEl = btnEls[buttonIndex];
    btnEl.className = btnEl.className + ' btn-disabled';
  };

  const resetStates = () => {
    toggleMsgLoader();
    disableDraftBtns(false);
    setDisabledSavedChatBtn(false);
    setTotalSearchQuestion(totalSearchQuestion + 1);
    setQuickButtons(renderQuickBtn());
    if(isExxonMobileEmail(context)) {
      disableQuickButton(1);
    }
    toggleInputDisabled();
    dispatch(removeGlobalFlag('lastQuestion'));
    localStorage.removeItem('lastQuestion');
  };

  const addClassForLables = () => {
    const messageBubbleEls: any = document.getElementsByClassName('rcw-message-text');
    if (messageBubbleEls && messageBubbleEls.length) {
      messageBubbleEls.forEach((messageBubbleEl: any, index: any) => {
        if (index === 0) {
          messageBubbleEl.classList.add('label-container');
        } else {
          messageBubbleEl.classList.add('label-container2');
        }
      });
    }
  };

  const renderQuickBtn = () => {
    return [
      // { label: 'View Other Drafts', value: 'other_draft' },
      { label: 'Save', value: 'save_chat'},
      { label: 'Save Prompts', value: 'favorites'},
    ];
  };

  const customResponseComponent: any = ({ tag, text, message }: any) => {
    const generateHyperlink = (painText: string) => {
      // Regular expression to match URLs in plain text
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Check if the message already contains <a> tags
      const containsAnchorTag = /<a\s+(?:[^>]*?\s+)?href=/.test(message);

      // Replace URLs with anchor tags only if <a> tags are not already present
      const linkedText = containsAnchorTag ? message : message.replace(urlRegex, (url: string) => `<a href="${url}" target="_blank">${url}</a><br />`);

      return { __html: linkedText };
    };
    return (
      <div className="rcw-response">
        <div className="rcw-message-text label-container2">
          {tag && (<><p>{tag}</p><br /></>)}
          {text && (<><p>{text} :</p><br /></>)}
          <p dangerouslySetInnerHTML={generateHyperlink(message)}/>
        </div>
      </div>
    );
  };

  const handleNewUserMessage = async (newMessage: any) => {
    // tslint:disable-next-line:no-console
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
    // const res = await axios.post('http://localhost:4000/chat', {prompt: newMessage});

    // tslint:disable-next-line:no-empty
    setTimeout(() => {
      handleFilePreview();
    });

    if(searchQueryText && defaultData) {
      setCurrentQuestion(newMessage);
      const data = {
        title: currentQuestion !== '' ? currentQuestion : searchQueryText,
        a1: defaultData,
        a2: defaultData1,
        a3: defaultData2,
        attachFiles: currentQuestion !== '' ? chatFilePreviews : [],
        order: 3
      };

      let storedChats = null;
      if(localStorage.getItem('tempChatData')) {
        storedChats = JSON.parse(localStorage.getItem('tempChatData') as string) || { data: [] };
      } else {
        storedChats = JSON.parse(localStorage.getItem('defaultChats') as string) || { data: [] };
      }

      setDefaultChat({
        mainTitle: defaultChat && defaultChat.mainTitle ? defaultChat.mainTitle : ((storedChats && storedChats.mainTitle && storedChats.data.length === 1) ? storedChats.mainTitle : searchQueryText),
        data: defaultChat && defaultChat.data ? [...defaultChat.data, data] : [data]
      });
    }

    if(favorites.length) {
      setFavorites([...favorites, {id: '', question: newMessage, isFavorite: false}]);
    } else {
      setFavorites([{id: '', question: searchQueryText, isFavorite: false}, {id: '', question: newMessage, isFavorite: false}]);
    }

    setDefaultData('');
    setDefaultData1('');
    setDefaultData2('');

    setChatFilePreviews(filePreviews);
    setFilePreviews([]);
    searchInBot(newMessage, activeProfile);

  };

  const handleFilePreview = () => {
    // Get all elements with the class "rcw-client"
    const elements = document.getElementsByClassName('rcw-client');

    // Check if any elements were found
    if (elements.length > 0) {
      // Get the last element in the collection
      const lastElement = elements[elements.length - 1];
      const mydata: any[] = Object.values(filePreviews);
      // Merge dom of question and attach files for file preview in chat area
      lastElement.innerHTML = renderToStaticMarkup(questionWithFile(mydata)) + lastElement.innerHTML;
    } else {
      // tslint:disable-next-line:no-console
      console.log('No elements found with the class \'rcw-client\'');
    }
  };

  const questionWithFile = (attachFiles: any) => {
    const data: any = [];
    typeof attachFiles === 'object' && Object.values(attachFiles).forEach((item: any) => {
      data.push(item);
    });

    return (
      <>
        {
          data && data.map((file: any) => {
            return imageTypes.includes(file.type) ? (
              <div className="preview-img mb-1">
                <img src={file.preview} alt={file.name} width="200" />
              </div>
            ) : (
              <div className="preview-file mb-1">
                <FileCopy color="primary" />
                <div>
                  <p>{ file.name }</p>
                  <p>{ file.type }</p>
                </div>
              </div>
            );
          })
        }
      </>
    );
  };

  React.useEffect(() => {
    if (queryParam.get('q')) {
      setSearchQueryText(queryParam.get('q'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warnAndRedirectToLogin = () => {
    toast.warn(`Please login to purchase any subscription.`);
    return history.push('/login');
  };

  React.useEffect(() => {
    if (queryParam.get('q')) {
      setSearchQueryText(queryParam.get('q'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryParam.get('q')) {
      setSearchQueryText(queryParam.get('q'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam.get('q')]);

  const adjustInputHeight = () => {
    const input: any = document.querySelector('.rcw-sender');
    if (input) {
      input.style.minHeight = '56px';
      input.style.minHeight = `${input.scrollHeight}px`; // Set to the scroll height
    }
  };

  useEffect(() => {
    // Adjust height on window resize
    const handleResize = () => {
      adjustInputHeight();
    };

    window.addEventListener('input', handleResize);
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('input', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Set logic for savedChat agency button behaviour
  useEffect(() => {
    const setData = async () => {
      if (searchInputRef.current !== null) {
        searchInputRef.current.focus();
      }
      if (aiToCeresRN) {
        if (searchInputRef.current !== null) {
          searchInputRef.current.value = aiToCeresTerm || '';
        }
        if (searchButton && searchButton.current) {
          searchButton.current.click();
        }
        localStorage.removeItem('RnNumber');
      }
      const param = window.location.pathname;
      const index = param.indexOf('=') + 1;
      const queryString = param.substring(index);
      if (index > 1 && searchButton.current && searchInputRef.current) {
        searchInputRef.current.value = queryString;
        searchButton.current.click();
      }
      if (searchButton.current && searchQueryText) {
        searchButton.current.click();
      }
      setIsChatFullScreen(false);
    };
    if(!!store.globalFlags.previousPage && openSavedChat && (store.globalFlags.previousPage === 'myenviroaiToAgency' || store.globalFlags.previousPage === 'myenviroaiToInternetResults')) {
      removeGlobalFlag('previousPage');
      setData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryText]);

  const aiToCeresRN = localStorage.getItem('RnNumber');
  const aiToCeresTerm = localStorage.getItem('searchTerm');
  const checkFilterStatus = () => {
    if (aiToCeresRN) {
      return true;
    }
    return false;
  };
  const [showFilter, setShowFilter] = useState(checkFilterStatus());
  const [showEnviroFilter, setShowEnviroFilter] = useState(false);
  const [currentQueryText, setCurrentQueryText] = useState<string>(
    (queryParam.get('q') ? queryParam.get('q') : '') as string
  );
  const searchInputRef = useRef<HTMLInputElement>(null);
  const rnInputRef = useRef<HTMLInputElement>(null);
  const applyButtonRef = React.createRef<HTMLElement>();
  const searchButton = React.createRef<HTMLElement>();

  const [sort, setSort] = useState('');
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const searchState: ISearch = store.search;
  const [totalVisitor, setTotalVisitor] = useState(0);
  const [myTotalVisitor, setMyTotalVisitor] = useState(0);
  // let gramPerSecond = 0;

  const startVistorTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    timerInterval = setInterval(() => {
      // if(gramPerSecond) {
      //   setTotalVisitor(t => +t + +gramPerSecond);
      // }
      setTotalVisitor((t) => +t + 1);
    }, 1500);
  };

  useEffect(() => {
    if (queryParam.get('q') === null) {
      setCurrentQueryText('');
      setSearchQueryText('');
      setCurrentQuestion('');
      setSearchResult(null);
      setCustomSearchResult(null);
    }
    if(localStorage.getItem('lastQuestion') !== queryParam.get('q')) {
      setQuickButtons([]);
    }
  }, [queryParam]);

  useEffect(() => {
    const type = queryParam.get('type') ? (queryParam.get('type') as CUSTOM_SEARCH_TYPE) : CUSTOM_SEARCH_TYPE.DEFAULT;
    setSearchType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam.get('type')]);

  useEffect(() => {
    if (currentQueryText) {
      getSearchCounterLeaderBoard();
      let searchText = `?q=${currentQueryText}&type=${searchType}`;
      if (isDirectCustomSearch) {
        searchText += '&directsearch=yes';
      }

      if (hideSearchBar) {
        searchText += '&hideSearchBar=true';
      }
      history.push({
        pathname: '/search',
        search: searchText,
      });
    }
    fetchTotalVisitors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryText, searchType, history]);

  useEffect(() => {
    dispatch(
      updateSearch({
        isSearch: searchResult || customSearchResult || queryParam.get('q') ? true : false,
        type: queryParam.get('type') || searchType,
        customSearchType: searchType === CUSTOM_SEARCH_TYPE.DEFAULT ? 'google' : customSearchType,
      })
    );
  }, [dispatch, searchResult, customSearchResult, searchType, customSearchType, queryParam]);

  // fetch total visitors
  const fetchTotalVisitors = async () => {
    const body = { action: 'total' };
    const bodyJson = JSON.stringify(body);
    try {
      const url = process.env.REACT_APP_VISITOR_URL as string;
      const response = await axios.post(url, bodyJson);
      if (response.data) {
        const totalCounter = response.data.total_counter;
        // if(response.data.gram_per_second) {
        //   gramPerSecond = response.data.gram_per_second;
        // }
        if (totalCounter) {
          if (!totalVisitor) {
            setTotalVisitor(totalCounter * 1000);
          }
          startVistorTimer();
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // update search visitors
  const postSearchVisitors = async () => {
    let useridval = '';
    let leaderboardnameval = '';
    const companynameval = '';
    if (context.isAuthenticated) {
      useridval = context.getUserEmail();
      leaderboardnameval = context.getUserName() ? context.getUserName() : context.getUserDisplayName();
    } else {
      try {
        const responseIP = await axios.get('https://geolocation-db.com/json/');
        if (responseIP.data) {
          if (responseIP.data.IPv4) {
            useridval = responseIP.data.IPv4;
          }
        }
      } catch (e) {
        // console.log(e);
      }
    }
    const body = {
      action: 'add-counter',
      user_id: useridval,
      leader_board_name: leaderboardnameval,
      company_name: companynameval,
    };
    const bodyJson = JSON.stringify(body);
    try {
      if (!isDirectCustomSearch) {
        const url = process.env.REACT_APP_VISITOR_URL as string;
        const response = await axios.post(url, bodyJson);
        if (response.data) {
          getMyTotalCounters(useridval);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // get my total search counters
  const getMyTotalCounters = async (useridval: any) => {
    if (useridval !== '') {
      const body = {
        action: 'user-total',
        user_id: useridval,
      };
      const bodyJson = JSON.stringify(body);
      try {
        const url = process.env.REACT_APP_VISITOR_URL as string;
        const response = await axios.post(url, bodyJson);
        if (response.data) {
          const mTCounter = response.data.user_total_counter;
          if (mTCounter) {
            setMyTotalVisitor(mTCounter);
          }
        }
      } catch (e) {
        // console.log(e);
      }
    }
  };

  // get leader board in search counter
  const getSearchCounterLeaderBoard = async () => {
    let groupbyval = '';
    if (searchType === CUSTOM_SEARCH_TYPE.DEFAULT) {
      groupbyval = 'company_name';
      // setIsProLeaderBoard(true);
    } else {
      groupbyval = 'leader_board_name';
      // setIsProLeaderBoard(false);
    }
    const body = {
      action: 'leader-board',
      group_by: groupbyval,
    };
    const bodyJson = JSON.stringify(body);
    try {
      const url = process.env.REACT_APP_VISITOR_URL as string;
      const response = await axios.post(url, bodyJson);
      if (response.data) {
        const leaderData = response.data.leader_board;
        if (leaderData) {
          // setLeaderBoards([]);
          const leaderArray: any[] = [];
          for (const oneItem of leaderData) {
            leaderArray.push(oneItem);
          }
          // setLeaderBoards(leaderArray);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handleSearchTypeChange = async (
    type: CUSTOM_SEARCH_TYPE,
    source: string = '',
    from: number = 1,
    sortVal: string = ''
  ) => {
    if (type === CUSTOM_SEARCH_TYPE.DEFAULT) {
      setSearchType(type);
      doTextSearch(currentQueryText, 0, source, false);
      return;
    }
    await handleCustomSearchTypeChange(type, currentQueryText, from, sortVal);
  };

  const handleCustomSearchTypeChange = async (
    type: CUSTOM_SEARCH_TYPE,
    text: string,
    from: number,
    sorting: string
  ) => {
    setIsLoading(true);
    setSearchType(type);
    setFrameSearchQueryText(text);
    return customSearch({
      type,
      query: text,
      from,
      sorting,
    })
      .then((result) => {
        return setCustomSearchResult(result.data);
      })
      .catch((err) => toast.error(err))
      .finally(() => {
        setIsLoading(false);
        // post search visitors
        postSearchVisitors();
      });
  };

  const handleSortOrderChange = (e: any) => {
    const value = (e && e.target && e.target.value) || '';
    setSort(value);
    handleSearchTypeChange(searchType, sourceType, 1, value);
  };

  const getProfileData = async (text: any) => {
    addUserMessage(text);
    toggleMsgLoader();
    const response: any = await saveProfile({ type: 'getAll' });
    toggleMsgLoader();
    if(response && response.data && response.data.length) {
      const profileData = response.data[0].payload.profileData.find((item: any) => item.enable === true);
      setActiveProfile(profileData ? profileData.title : null);
      if(profileData && profileData.title) {
        searchInBot(text, profileData.title);
      } else {
        searchInBot(text);
      }
    } else {
      // addUserMessage(text);
      searchInBot(text);
    }
  };

  const toggleChatForSearch = async (text: any) => {
    // reset default chat in local storage
    localStorage.removeItem('defaultChats');
    // reset temp chat in local storage
    localStorage.removeItem('tempChatData');
    setDefaultChat(null);
    setDefaultData('');
    setDefaultData1('');
    setDefaultData2('');

    if (!text) {
      return;
    }
    if(localStorage.getItem('lastQuestion') !== text) {
      dropMessages();
    }

    if (!isWidgetOpened()) {
      return;
      // setpopup(true);
      // toggleWidget();
    }
    if(localStorage.getItem('lastQuestion') !== text) {
      await getProfileData(text);
    } else {
      dispatch(removeGlobalFlag('lastQuestion'));
      localStorage.removeItem('lastQuestion');
    }
  };

  const doTextSearch = (queryText: string = currentQueryText, from = 0, source: string, qSearch = true) => {
    if (!isDirectCustomSearch) {
      setSearchType(queryParam.get('type') ? queryParam.get('type') as CUSTOM_SEARCH_TYPE : CUSTOM_SEARCH_TYPE.DEFAULT);
    }
    // if(queryParam.get('type') === 'ALL') {
    //   handleToggle(false);
    //   WidgetClose();
    // }
    setFrameSearchQueryText(queryText);
    setIsLoading(true);
    setCustomSearchResult(null);
    // post search visitors
    // postSearchVisitors();
    return search(
      applyFilterToQuery(
        {
          query: {
            from,
            text: queryText + (qSearch ? ' ' + quickSerch : ''),
            source_type: source ? source : null,
          },
        },
        filter || {},
        CUSTOM_SEARCH_TYPE.DEFAULT
      )
    )
      .then((result) => {
        const data = result.data as IElasticResult<ISearchRecord>;
        const epnArray: any[] = [];
        data.records.map((ele: ISearchRecord) => {
          if (ele.location.geomz) {
            const decryptedData = CryptoJS.AES.decrypt(ele.location.geomz, secKey, {
              iv,
              mode: CryptoJS.mode.CBC,
            }).toString(CryptoJS.enc.Utf8);
            const latLon = decryptedData.split(',');
            ele.location.lon = parseFloat(latLon[0]);
            ele.location.lat = parseFloat(latLon[1]);
          }
          if (ele.location.geom_json) {
            for (const epnKey in ele.location.geom_json) {
              if (ele.location.geom_json.hasOwnProperty(epnKey)) {
                for (const epnInnerKey in ele.location.geom_json[epnKey]) {
                  if (ele.location.geom_json[epnKey].hasOwnProperty(epnInnerKey)) {
                    const element = ele.location.geom_json[epnKey][epnInnerKey];
                    const epnRecord: any = {};
                    if (element.geom) {
                      const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                        iv,
                        mode: CryptoJS.mode.CBC,
                      }).toString(CryptoJS.enc.Utf8);
                      const latLon = decryptedData.split(',');
                      epnRecord.location = Object.assign({});
                      epnRecord.location.lon = parseFloat(latLon[0]);
                      epnRecord.location.lat = parseFloat(latLon[1]);
                      epnRecord.marker = element.marker;
                      epnRecord.name = element.name;
                      epnRecord.type = element.type;
                      epnRecord.details = element.details;
                      epnArray.push(epnRecord);
                    }
                  }
                }
              }
            }
          }
          return ele;
        });
        setSearchResult(data);
        setDocSearchEPNResult(epnArray);
      })
      .finally(() => {
        setIsLoading(false);
        if (!qSearch) {
          setQuickFilter(false);
        }
      });
  };

  useEffect(() => {
    if (currentQueryText && searchType !== 'MAP') {
      handleSearchTypeChange(searchType, sourceType, 1, sort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCustomSearch = () => {
    if (searchType === CUSTOM_SEARCH_TYPE.DEFAULT) {
      return <></>;
    }
    if (customSearchResult || queryParam.get('q')) {
      return (
        <>
          {searchType === 'MAP' ? (
            <CustomSearchMapOption text={currentQueryText} queryParam={queryParam} />
          ) : (
            <GoogleCustomSearch
              result={customSearchResult}
              onNextClick={() => {
                if (!customSearchResult || !customSearchResult.next) {
                  return;
                }
                return handleSearchTypeChange(searchType, sourceType, customSearchResult.next.startIndex, sort);
              }}
              onPreviousClick={() => {
                if (!customSearchResult || !customSearchResult.previous) {
                  return;
                }
                return handleSearchTypeChange(searchType, sourceType, customSearchResult.previous.startIndex, sort);
              }}
              handleSortOrderChange={handleSortOrderChange}
              isLoading={isLoading}
              sort={sort}
              searchType={searchType}
              text={currentQueryText}
              tCounter={myTotalVisitor}
            />
          )}
        </>
      );
    }
  };

  const main = useClickOutside(() => {
    if(showProfile || showSavedPrompts || showQuestionsPopup || showSaveLibrary) {
      return;
    }

    WidgetClose();
  });

  const WidgetClose = (() => {
    if (isWidgetOpened()) {
      if (isChatFullScreen) {
        setIsChatFullScreen(false);
      }
      // setTotalSearchQuestion(0);
      localStorage.removeItem('defaultChats');
      // set chat in localhost to display again when chat widget re-open
      setLocalhostChatData('tempChatData');
      if(!!searchQueryText && !!history.location.search) {
        dispatch(updateGlobalFlags({ lastQuestion: searchQueryText }));
        localStorage.setItem('lastQuestion', searchQueryText);
      }
      toggleWidget();
      setpopup(false);
    }
  });

  // Handle chat data when chat widget close and re-open
  useEffect(() => {
    const storedChats = JSON.parse(localStorage.getItem('tempChatData') as string) || { data: [] };
    if(isWidgetOpened()) {
      const div = document.getElementById('messages');

      // When search new question from new search and already same question in localStorage
      if((localStorage.getItem('lastQuestion') === searchQueryText) && (div && div.childNodes.length < 2)) {
        localStorage.removeItem('lastQuestion');
      }

      if(localStorage.getItem('lastQuestion') !== searchQueryText) {
        localStorage.removeItem('tempChatData');
        toggleChatForSearch(searchQueryText);
      } else {
        if(storedChats && storedChats.data && storedChats.data.length) {
          // Don't drop and reload messages if there are no drafts loaded previously.
          if(defaultData && defaultData1 && defaultData2) {
            dropMessages();
            setMainChatData(storedChats);
          }
          localStorage.removeItem('tempChatData');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidgetOpened()]);

  const handleOpenSavedChats = async (status: boolean) => {
    dropMessages();
    setOpenSavedChat(status);
    const storedChats = JSON.parse(localStorage.getItem('defaultChats') as string) || { data: [] };

    if(status) {
      setLocalhostChatData('defaultChats', storedChats);
    } else {
      setMainChatData(storedChats);
    }
  };

  // set chat data in localhost to display again
  const setLocalhostChatData = (localStorageKey: string, storedChats?: any) => {
    const data = {
      title: currentQuestion !== '' ? currentQuestion : searchQueryText,
      a1: defaultData,
      a2: defaultData1,
      a3: defaultData2,
      attachFiles: chatFilePreviews,
      order: 3
    };

    const mainTitle = storedChats && storedChats.mainTitle ? storedChats.mainTitle : searchQueryText;

    const chatsData = defaultChat && defaultChat.data ? [...defaultChat.data, data] : [data];
    localStorage.setItem(localStorageKey, JSON.stringify({ mainTitle, data: chatsData}));
  };

  // set chat data in chatbot which are stored in localhost
  const setMainChatData = (storedChats: any) => {
    if(defaultData1 && defaultData2) {
      setQuickButtons(renderQuickBtn());
      if(isExxonMobileEmail(context)) {
        disableQuickButton(1);
      }
    }

    if(storedChats && storedChats.mainTitle) {
      setDefaultChat({...defaultChat, mainTitle: storedChats.mainTitle});
    }

    storedChats && storedChats.data && storedChats.data.forEach((answerData: any, index: number) => {

      // Set file preview in chat
      setTimeout(() => {
          addUserMessage(answerData['title']);
          const elements = document.getElementsByClassName('rcw-client');
          if(elements.length) {
            const lastElement = elements[elements.length - 1];
            // Merge dom of question and attach files for file preview in chat area
            lastElement.innerHTML = renderToStaticMarkup(questionWithFile(answerData.attachFiles)) + lastElement.innerHTML;
          }
          const copyAnswerData = {
            ...answerData
          };

          delete copyAnswerData.title;
          delete copyAnswerData.order;
          delete copyAnswerData.attachFiles;

          Object.values(copyAnswerData).forEach((answer: any, i: number) => {
            if(answer !== '') {
              const answerParts = answer.split('\n\n');
              const data = {
                tag: answerParts[0],
                text: '',
                message: answerParts.slice(1, answerParts.length).join('\n\n')
              };
              renderCustomComponent(customResponseComponent, data);
            }
          });
        }, index * 500);
    });
  };

  useEffect(() => {
    if(popup && !openSavedChat) {
      appendActiveProfileTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup, openSavedChat, activeProfile]);

  useEffect(() => {
    // tslint:disable-next-line:no-console
    console.log('saved chats default ==>>>', defaultChat);
    // tslint:disable-next-line:no-console
    console.log('saved chats localstorage default ==>>>', JSON.parse(localStorage.getItem('defaultChats') as string));
  }, [defaultChat]);

  useEffect(() => {
    const widgetContainer = document.getElementById('rcw-conversation-container');
    if (!isLoading && toggleMobileScreen && window.innerWidth <= 798 && widgetContainer) {
      toggleWidget();
      setToggleMobileScreen(false);
    }
    if(!!store.globalFlags.previousPage && localStorage.getItem('lastQuestion') !== searchQueryText) {
      if(isWidgetOpened()) {
        toggleWidget();
        setpopup(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleToggle = (event: any) => {
    if (event) {
      setpopup(true);
    } else {
      setpopup(false);
      // set chat in localhost to display again when chat widget re-open
      setLocalhostChatData('tempChatData');
      if(!!searchQueryText) {
        dispatch(updateGlobalFlags({ lastQuestion: searchQueryText }));
        localStorage.setItem('lastQuestion', searchQueryText);
      }
    }
  };

  const renderDefaultSearch = () => {
    return (
      <SearchResultContainer
        onPreviousClick={() => {
          if (!searchResult) {
            return;
          }
          const currentFrom = searchResult.from;
          const currentSize = searchResult.size;
          setIsLoading(true);
          return doTextSearch(currentQueryText, currentFrom - currentSize, sourceType);
        }}
        onNextClick={() => {
          if (!searchResult) {
            return;
          }
          const currentFrom = searchResult.from;
          const currentSize = searchResult.size;
          return doTextSearch(currentQueryText, currentFrom + currentSize, sourceType);
        }}
        result={searchResult}
        docSearchEPNResult={docSearchEPNResult}
        setQuickSerch={setQuickSerch}
        quickSerch={quickSerch}
        isQuickFilter={isQuickFilter}
        setQuickFilter={setQuickFilter}
        sourceType={sourceType}
        doTextSearch={doTextSearch}
        isLoading={isLoading}
        currentQueryText={currentQueryText}
        applyFilterToQuery={applyFilterToQuery}
        filter={filter}
      />
    );
  };

  const appendActiveProfileTag = () => {
    const activeProfileElement = document.getElementById('active-profile');
    if(activeProfileElement) {
      activeProfileElement.remove();
    }
    if(!activeProfile) {
      return;
    }

    const el = document.createElement('p');
    el.id = 'active-profile';
    el.className = 'text-center text-dark my-2 alert alert-info font-weight-bold active-profile';
    el.innerHTML = 'Enabled Profile: ' + activeProfile.substring(0, 50) + '...';
    const div = document.getElementById('messages');
    if(div && div.parentNode) {
      div.parentNode.insertBefore(el, div.nextSibling);
    }
  };

  useEffect(() => {
    // handleFileUploadButton();
    if (searchInputRef.current !== null) {
      searchInputRef.current.focus();
    }
    if (aiToCeresRN) {
      if (searchInputRef.current !== null) {
        searchInputRef.current.value = aiToCeresTerm || '';
      }
      if (searchButton && searchButton.current) {
        searchButton.current.click();
      }
      localStorage.removeItem('RnNumber');
    }
    const param = window.location.pathname;
    const index = param.indexOf('=') + 1;
    const queryString = param.substring(index);
    if (index > 1 && searchButton.current && searchInputRef.current) {
      searchInputRef.current.value = queryString;
      searchButton.current.click();
    }
    if (searchButton.current && searchQueryText) {
      searchButton.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper function to convert Blob to base64
  const blobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Function to be called when button is clicked
  const handleUploadFiles = (event: any) => {
    if(!event || !event.target) {
      return;
    }
    let latestFiles: any[] = [];
    setFiles((prevState: any) => {
      latestFiles = prevState;
      return prevState;
    });

    // tslint:disable-next-line:no-console
    console.log('oldFiles=>', latestFiles);

    const selectedFiles: any[] = Array.from(event.target.files);
    // tslint:disable-next-line:no-console
    console.log('newFiles=>', selectedFiles);
    // Reset the file input value to allow selecting the same file again
    event.target.value = '';
    const totalFiles = [...latestFiles, ...selectedFiles];

    setFiles(totalFiles);
    setFilePreviews([]);
    totalFiles.forEach(async (file) => {
      if(imageTypes.includes(file.type)) {
        const base64: any = await blobToBase64(file);
        setFilePreviews((prevState: any) => [...prevState, {
          preview: base64,
          name: file.name,
          type: file.type
        }]);
      } else {
        setFilePreviews((prevState: any) => [...prevState, {
          preview: URL.createObjectURL(file),
          name: file.name,
          type: file.type
        }]);
      }
    });
    // tslint:disable-next-line:no-console
    console.log('files=>', files);
  };

  useEffect(() => {
    if(!popup) {
      return;
    }

    const fileDiv: any = document.createElement('div');
    fileDiv.className = 'image-upload';

    const fileLabel: any = document.createElement('label');
    fileLabel.htmlFor = 'file-input';
    fileLabel.className = isExxonMobileEmail(context) ? 'text-secondary' : 'text-dark';
    fileLabel.title = isExxonMobileEmail(context) ? 'This feature is disabled for ExxonMobil. Please contact the Administrator.' : '';
    fileLabel.style = 'cursor: pointer; color: black;';

    // Create the icon element
    const icon: any = document.createElement('span');
    ReactDOM.render(<AttachFile />, icon);

    fileLabel.appendChild(icon);

    const fileInput: any = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = 'file-input';

    fileLabel.addEventListener('click', (event: MouseEvent) => {
      if (isExxonMobileEmail(context)) {
        event.preventDefault(); // Prevent the default click action
        showToastMessage({
          type: 'warn',
          title: 'Access Denied',
          description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
        });
      }
    });
    fileInput.style = 'display: none';
    fileInput.disabled = isExxonMobileEmail(context);
    fileInput.multiple = true;
    fileInput.onchange = handleUploadFiles;

    fileDiv.appendChild(fileLabel);
    fileDiv.appendChild(fileInput);

    // Find the div with the class 'rcw-sender' and append the button
    const senderDiv = document.getElementsByClassName('rcw-sender')[0];

    if (senderDiv) {
      senderDiv.prepend(fileDiv);
      addParentDiv();
    } else {
      // tslint:disable-next-line:no-console
      console.error('No elements found with the class name "rcw-sender".');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcwButton, popup]);

  // Add parent div to wrap chat input row and seperate from img preview
  const addParentDiv = () => {
    // Create the new parent div
    const newParentDiv = document.createElement('div');
    newParentDiv.className = 'chat-input';

    // Select the rcw-sender div
    const senderDiv = document.querySelector('.rcw-sender');
    if(!senderDiv) {
      return;
    }

    const pickerBtn = document.querySelector('.rcw-picker-btn');
    if(pickerBtn) {
      pickerBtn.remove();
    }

    // Select the children elements
    const imageUpload = document.querySelector('.image-upload');
    const newMessage = document.querySelector('.rcw-new-message');
    const sendBtn = document.querySelector('.rcw-send');

    if(!imageUpload || !newMessage || !sendBtn) {
      return;
    }

    // Append the children to the new parent div
    newParentDiv.appendChild(imageUpload);
    newParentDiv.appendChild(newMessage);
    newParentDiv.appendChild(sendBtn);
    senderDiv.innerHTML = '';
    // Append the new parent div to the rcw-sender div
    senderDiv.appendChild(newParentDiv);
  };

  /**
   * Remove attachment files in search input
   * @param index
   */
  const removeAttachment = (index: number) => {
    const filteredFiles = files.filter((file: any, i: number) => i !== index);
    const filteredFilePreviews = filePreviews.filter((file: any, i: number) => i !== index);
    setFiles(filteredFiles);
    setFilePreviews(filteredFilePreviews);
    setChatFilePreviews([...filteredFilePreviews]);
  };

  // When filePreview contains data, it creates html dom for file preview
  useEffect(() => {
    filePreviewDomCreate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePreviews]);

  // Create file preview html dom in chat input section
  const filePreviewDomCreate = () => {
    const imgPreviews = document.querySelectorAll('.files-container');
    imgPreviews.forEach((divBlock) => {
      divBlock.remove();
    });
    const filesContainer = document.createElement('div');
    filesContainer.className = 'files-container d-flex';

    // Select the rcw-sender div
    const senderDiv: any = document.querySelector('.rcw-sender');
    if(!senderDiv) {
      return;
    }
    if(Array.isArray(filePreviews) && filePreviews.length) {
      senderDiv.style = 'min-height: 122px';
    } else {
      senderDiv.style = '';
    }

    Array.isArray(filePreviews) && filePreviews.forEach((file, index) => {
      /** File preview container for each file */
      const filePreviewContainer = document.createElement('div');
      if(imageTypes.includes(file.type)) {
        filePreviewContainer.className = 'preview-img mr-1';

        /** Image preview html element */
        const imgDiv = document.createElement('img');
        imgDiv.src = file.preview;
        imgDiv.alt = file.name;
        imgDiv.width = 200;
        imgDiv.className = 'img-container';

        // Remove button icon
        const removeButton = document.createElement('button');
        // Set attributes and class names
        removeButton.setAttribute('variant', 'outline-dark');
        removeButton.className = 'close-button btn btn-outline-dark';
        // Create the close icon element
        const closeIcon: any = document.createElement('span');
        closeIcon.className = 'rounded-close-button';
        ReactDOM.render(<CloseRounded />, closeIcon);
        removeButton.appendChild(closeIcon);
        // Step 4: Set the onclick event handler
        removeButton.onclick = () => removeAttachment(index);

        filePreviewContainer.appendChild(imgDiv);
        filePreviewContainer.appendChild(removeButton);
        filesContainer.appendChild(filePreviewContainer);
        senderDiv.prepend(filesContainer);
      } else {
        filePreviewContainer.className = 'preview-file mr-1';
        // Add file icon
        ReactDOM.render(<FileCopy color="primary" />, filePreviewContainer);

        // Create file details container div element
        const fileDetails = document.createElement('div');
        const fileNameDom: any = document.createElement('p');
        const fileTypeDom: any = document.createElement('p');
        fileNameDom.innerText = file.name;
        fileDetails.appendChild(fileNameDom);
        fileTypeDom.innerText = file.type;
        fileDetails.appendChild(fileTypeDom);

        // Remove button icon
        const removeButton = document.createElement('button');
        // Set attributes and class names
        removeButton.setAttribute('variant', 'outline-dark');
        removeButton.className = 'close-button btn btn-outline-dark';
        // Create the close icon element
        const closeIcon: any = document.createElement('span');
        closeIcon.className = 'rounded-close-button';
        ReactDOM.render(<CloseRounded />, closeIcon);
        removeButton.appendChild(closeIcon);
        // Set the onclick event handler
        removeButton.onclick = () => removeAttachment(index);

        filePreviewContainer.appendChild(fileDetails);
        filePreviewContainer.appendChild(removeButton);
        filesContainer.appendChild(filePreviewContainer);
        senderDiv.prepend(filesContainer);
      }
    });
  };

  const handleProfile = (status: boolean) => {
    setShowProfile(status);
  };

  const handleCopyToChat = (title: string) => {
    const rcwChatInput: any = document.querySelector('.rcw-input');
    if (rcwChatInput) {
      rcwChatInput.textContent = title;
    }
    setTimeout(() => {
      adjustInputHeight();
    });
  };

  const handleToggleProfile = (profileTitle: string) => {
    setActiveProfile(profileTitle);
  };

  const showSearchTypePill = () =>
    ((searchType === 'default' && context.isAuthenticated) || searchType !== 'default') &&
    (context.isLubrizol() ||
      context.isTier(TIERS.PLATINUM) ||
      context.isTier(TIERS.DAYPASS) ||
      context.isTier(TIERS.SUPER_ADMIN) ||
      searchType !== 'default') &&
    (!!searchResult || !!customSearchResult);

  const userTier = context.getUserTier();

  const backgroundImage = require('../../assets/dayPassContainerImg.png');

  const tundraProImage = require('../../assets/dayPassTundraProImg.png');

  const buyTundraPro = require('../../assets/dayPassBuyImg.png');
  const classes = useStyles();
  return (
    <>
      <HomeTour
        showTour={isTourOpen}
        onGotItClick={() => {
          localStorage.setItem('walkthrough', 'false');
        }}
        onCloseTour={() => {
          setTourOpen(false);
        }}
      />
      <HomeContainer>
        {
          (searchState.isSearch && searchType === 'default' && !isLoading && context.isTier(TIERS.FREE) && !context.isLubrizol()) ?

            <DaypassContainer>
              <Back>
                <img src={backgroundImage} alt="DayPass" />
                {
                  isDayPassLoading ?
                    <CircularProgress color="secondary" className={classes.loaderContainer} />
                    : null
                }
                <div className="pro"><img src={tundraProImage} alt="Tundra Pro" /></div>
                <div><img src={buyTundraPro} alt=" Buy Tundra Pro" onClick={() => {
                  if (!context.isAuthenticated) {
                    return warnAndRedirectToLogin();
                  }
                  if (context.isTier(TIERS.PLATINUM) || context.isLubrizol()) {
                    return toast.warn(`You are already a PLATINUM subscriber`);
                  }
                  if (context.isTier(TIERS.DAYPASS)) {
                    return toast.warn(`You are already a DAYPASS subscriber`);
                  }
                  setIsDayPassLoading(true);
                  CeresStripePayments.doCheckout(
                    'DAYPASSNEW',
                    context.getUserEmail(),
                    true
                  )
                    .then(
                      ({ error }: { error: any }) => {
                        setIsDayPassLoading(false);
                        error && toast.error(error.message);
                      }
                    )
                    .catch((error: { message: string }) => {
                      setIsDayPassLoading(false);
                      toast.error(error.message);
                    });
                }} /></div>
              </Back>
            </DaypassContainer>
            : null
        }
        <SearchContainerMain tier={userTier} issearch={searchState && searchState.isSearch ? 'yes' : ''}>
          {!(searchState && searchState.isSearch) && (
            <CeresLogoContainerItem>
              <CeresBotHeroImage src={EnviroAILogo} alt="EnviroAI" />
              <p className="mb-0">Digital Environmental Assistant</p>
             <EnviroAiTriangleContainer>
                <Link to="/myenviroai" className="logo-container">
                  <img data-tip="Ask EnviroAI" src={EnviroAiTriangle} alt="EnviroAITriangle" />
                </Link>
                <LaunchText>LAUNCH</LaunchText>
              </EnviroAiTriangleContainer>
            </CeresLogoContainerItem>
          )}
          {!hideSearchBar && <SearchFormContainerItem data-tour="ceres-first-step">
            <SearchForm
              isFilterApplied={!!filter}
              onFilterClick={() => setShowFilter(true)}
              onEnviroFilterClick={() => setShowEnviroFilter(true)}
              isLoading={searchType === 'default' ? isLoading : false}
              inputRef={searchInputRef}
              searchRef={searchButton}
              queryText={searchQueryText}
              setQueryText={setSearchQueryText}
              onSearchSubmit={(queryText: string) => {
                if (!!!queryText) {
                  return;
                }
                if (!!!queryText && !!!filter) {
                  return;
                }
                setQuickSerch('');
                setCurrentQuestion('');
                setFavorites([]);
                setTotalSearchQuestion(0);
                setCurrentQueryText(queryText);
                if (context.isAuthenticated && queryParam.get('type') !== 'ALL') {
                  // toggleChatForSearch(queryText);
                  return doTextSearch(queryText, 0, '', false);
                }
                // toggleChatForSearch(queryText);
                doTextSearch(queryText, 0, '', false);
                return handleCustomSearchTypeChange(
                  (queryParam.get('type') ? queryParam.get('type') : CUSTOM_SEARCH_TYPE.ALL) as CUSTOM_SEARCH_TYPE,
                  queryText,
                  1,
                  sort
                );
              }}
            />
            {searchState && searchState.isSearch && !isDirectCustomSearch && (

              <ShowLogoArea>
                {searchType !== 'default' && customSearchType === 'environmental' ? (
                  <BackLink
                    onClick={() => {
                      setCustomSearchType('google');
                      setSearchType(CUSTOM_SEARCH_TYPE.ALL);
                      handleSearchTypeChange(CUSTOM_SEARCH_TYPE.ALL, '', 1, sort);
                    }}
                  >
                    <ChevronLeft /> Back to Normal Search Results
                  </BackLink>
                ) : searchType === 'default' ? (
                  <BackLink
                    onClick={() => {
                      setCustomSearchType('google');
                      setSearchType(CUSTOM_SEARCH_TYPE.ALL);
                      handleSearchTypeChange(CUSTOM_SEARCH_TYPE.ALL, '', 1, sort);
                    }}
                  >
                    <ChevronLeft /> Get Normal Search Results
                  </BackLink>
                ) : (process.env.REACT_APP_CHROME_EXTENSION_URL && isChrome && !isExtensionInstalled) ? (
                  <Extension>
                    <Button
                      onClick={() => {
                        window.open(process.env.REACT_APP_CHROME_EXTENSION_URL, '_blank');
                      }}
                    >
                      <img src="/icon/chrome.png" alt="chrome" />
                      Add EnviroAI to Chrome
                    </Button>
                  </Extension>
                ) : (
                  <div></div>
                )}
                <LogoArea>
                  {searchType === 'default' && <Title src={EnviroAILogo} alt="Enviro Logo" />}
                  {/* <BackLink
                    onClick={() => {
                      setIsCollapsed(false);
                    }}
                  >
                    <img src={Logo} alt="" />
                  </BackLink>
                  <RightSideTotalSearchDialog
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    tCounter={myTotalVisitor}
                    boards={leaderBoards}
                    isProLeaderBoard={isProLeaderBoard}
                    isLoggedIn={context.isAuthenticated}
                  /> */}
                </LogoArea>
              </ShowLogoArea>
            )}
            <SidebarOverlay onClick={() => setIsCollapsed((prevState) => !prevState)} isActive={!isCollapsed} />
          </SearchFormContainerItem>}
          {/* {!(searchState && searchState.isSearch) && (
            <div>
              <CeresMessage>
                <p>You can ask me questions ...</p>
                <div className="question-box">EnviroAI Questions</div>
              </CeresMessage>
              <CeresMessage>
                <p>... and you can ask me to help you do things ...</p>
                <div className="question-box">What is flares?</div>
              </CeresMessage>
            </div>
            )} */}
          {showSearchTypePill() && !isDirectCustomSearch && (
            <SearchTypePills
              active={searchType}
              isDisabled={false}
              isOnlyDocumentSearch={!!!currentQueryText}
              setSearchType={setSearchType}
              sourceType={sourceType ? sourceType : null}
              setSourceType={setSourceType}
              filters={filters}
              setFilter={setFilter}
              setQuickSerch={setQuickSerch}
              onTypeChange={(type: CUSTOM_SEARCH_TYPE, source: string) => {
                type !== 'MAP' && handleSearchTypeChange(type, source, 1, sort);
              }}
              searchType={searchType}
              customSearchType={customSearchType}
              setCustomSearchType={setCustomSearchType}
            />
          )}
        </SearchContainerMain>
        <SearchResultMainContainer tier={userTier}>
          {searchType === CUSTOM_SEARCH_TYPE.DEFAULT && renderDefaultSearch()}
          {searchType !== CUSTOM_SEARCH_TYPE.DEFAULT && renderCustomSearch()}
        </SearchResultMainContainer>
        <AdvancedSearchFilter
          rnInputRef={rnInputRef}
          applyButtonRef={applyButtonRef}
          onFilterClear={() => setFilter(null)}
          onFilterApply={(newFilter) => {
            filter = newFilter;
            setFilter(newFilter);
            setShowFilter(false);
          }}
          onClose={() => setShowFilter(false)}
          show={showFilter}
          doTextSearch={doTextSearch}
          currentQueryText={currentQueryText}
          isLoading={isLoading}
          sourceType={sourceType}
          setSourceType={setSourceType}
        />
        <EnviroSearchFilter
          show={showEnviroFilter}
          onClose={() => setShowEnviroFilter(false)}
          queryText={searchQueryText}
          setQueryText={setSearchQueryText}
        />
        {/* <CountNumber totalCounter={totalVisitor} /> */}
        {/* <Bookdemo>
          <a href="https://calendly.com/jed-enviroai" target="_blank" rel="noopener noreferrer">Book a demo for Pro</a>
        </Bookdemo> */}
      </HomeContainer>
      {
        (!context.isTier(TIERS.FREE)) ?
        <>
            <QuestionsListModal
              show={showQuestionsPopup}
              onHide={() => setShowQuestionsPopup(false)}
            />
            { isChatFullScreen && openSavedChat &&
              <SavedChats
                activeSavedChatId={savedChatId}
                activeProfile={activeProfile}
                closeSavedChat={handleOpenSavedChats}
                handleProfile={handleProfile}
                filePreviews={filePreviews}
                setFilePreviews={setFilePreviews}
                files={files}
                setFiles={setFiles}
                setShowSavedPrompts={setShowSavedPrompts}
                setShowSaveLibrary={setShowSaveLibrary}
              />}
            { isChatFullScreen && showProfile && <Profile closeProfile={handleProfile} onToggleProfile={handleToggleProfile} /> }
            { isChatFullScreen && showSavedPrompts && <SavedPrompts context={context} closeSavedPrompt={setShowSavedPrompts} onCopyText={handleCopyToChat} /> }
            { isChatFullScreen && showSaveLibrary && <SavedLibrary context={context} closeSavedLibrary={setShowSaveLibrary} onCopyText={handleCopyToChat} /> }
            {!openSavedChat &&
              <Container ref={main} >
                <ChatBox>

                  <div style={{
                    visibility: searchState.isSearch && !isLoading && !isDirectCustomSearch ? 'visible' : 'hidden'
                  }}>
                    <Widget
                      launcherOpenImg={EnviroAiTriangle}
                      handleQuickButtonClicked={handleQuickButtonClickFn}
                      showCloseButton={false}
                      fullScreenMode={isChatFullScreen}
                      handleToggle={handleToggle}
                      handleNewUserMessage={handleNewUserMessage}
                      title={
                        <TitleContainer>
                          { isChatFullScreen &&
                              <button className="btn btn-outline-light btn-sm" onClick={() => handleOpenSavedChats(true)} disabled={disabledSavedChatBtn}>
                                <ChatIcon /> Saved Chats
                              </button>
                           }
                          { isChatFullScreen &&
                              <button className="btn btn-outline-light ml-2" onClick={() => handleProfile(true)}>
                                <PersonIcon /> Profile
                              </button>
                           }
                          { isChatFullScreen &&
                              <button className="btn btn-outline-light ml-2" onClick={() => setShowSavedPrompts(true)}>
                                <ClearAllIcon />Saved Prompts
                              </button>
                           }
                          { isChatFullScreen &&
                              <button
                              id="library-btn"
                              className="btn btn-outline-light ml-2"
                              onClick={() => {setShowSaveLibrary(true);}}
                            >
                              <LibraryBooksIcon /> Saved Library
                            </button>
                          }
                          <div className={`title-head ${isChatFullScreen ? 'title-head-fullscreen' : ''}`}>
                            <img src={HeaderEnviroAILogo} alt="EnviroAI Logo" />
                          </div>
                          {
                            isChatFullScreen &&
                            (<div className="environmental">
                              <p>Environmentally<sup> Trained</sup> Generative AI</p>
                            </div>)
                          }
                          <div className="icon-container">
                         <FiMaximize2 className="max-btn" style={{ visibility: searchState.isSearch && !isLoading && !isDirectCustomSearch && !isChatFullScreen ? 'visible' : 'hidden', zIndex: 998 }} onClick={chatUi} />
                            <FiMinimize2 className="max-btn" style={{ visibility: isChatFullScreen ? 'visible' : 'hidden', zIndex: 998 }} onClick={chatUi} />
                            <button className="close-Chat" style={{ visibility: searchState.isSearch && !isLoading && !isDirectCustomSearch ? 'visible' : 'hidden' }} onClick={WidgetClose}>X</button>
                          </div>
                        </TitleContainer>
                      }
                      subtitle=""
                      emojis={false}
                      sendButtonAlt="Send"
                    >
                    </Widget>
                  </div>
                </ChatBox>
                <IFrameContainer style={{
                  visibility: searchState.isSearch && !isLoading && popup && !isDirectCustomSearch ? 'visible' : 'hidden', zIndex: 994
                }}>
                  <Container maxWidth="sm" className="title-container">
                    <h4 className="web-title"> EnviroWeb </h4>
                  </Container>
                    <button className="close-iframe" onClick={closepopup}>X</button>
                    <FiMaximize2 className="maximize-btn" onClick={routeChange} />
                      <div style={{ height: '89%' }}>
                    <iframe title="iframe" width="100%" className="iframe-title" src={`/search?q=${frameSearchQueryText}&type=${CUSTOM_SEARCH_TYPE.INTERNET}&directsearch=yes&hideSearchBar=true`} height="100%"></iframe>
                  </div>
                </IFrameContainer>
              </Container>
            }
          </>
          : null
      }
    </>
  );
};

export default withAuthContext(CeresHome);
